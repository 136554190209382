<template>
  <div id="brand">
    <div class="hadheight"></div>
    <Header @brandSelect="brandSelect" />
    <div class="crumbsbox" v-show="brandlist">
        <div class="basecont" >
            <router-link to="/">首页</router-link>
            <span>&gt;</span>
            <router-link to="/brand">B2P</router-link>
            <span v-if="xie">&gt;</span>
            <a href="javascript:;" class="on" >{{xie}}</a>
        </div>
    </div>
    <div class="brandlist-sec1" v-show="brandlist">
        <form action="" class="fom">
            <div class="basecont">
                <div class="cont">
                    <!-- 地区 -->
                    <div class="item" v-if="regionSelect">
                        <div class="list">
                            <div class="tit" @click="itmToggle($event,0)">
                                <i><img src="../../assets/images/iconimgfselew1.png" alt=""></i>
                                <span>{{regionChecked}}</span>
                                <div class="san">
                                    <img src="../../assets/images/sanjiaoseleimgf1.png" alt="">
                                </div>
                            </div>
                            <div class="f_jut" >
                                <a href="javascript:;" @click="getRegionSele(regionChecked2)">
                                  {{regionChecked2}}
                                </a>
                                <template  v-for="(item, index) in regionSelect">
                                  <a href="javascript:;" 
                                     :data-option="item"
                                     @click="getRegionSele(item)"
                                     :key="index">
                                    {{item}}
                                  </a>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 风格 -->
                    <div class="item" v-if="styleSelect">
                        <div class="list">
                            <div class="tit" @click="itmToggle($event,1)">
                                <i><img src="../../assets/images/iconimgfselew1.png" alt=""></i>
                                <span>{{styleChecked}}</span>
                                <div class="san">
                                    <img src="../../assets/images/sanjiaoseleimgf1.png" alt="">
                                </div>
                            </div>
                            <div class="f_jut" >
                                <a href="javascript:;" @click="getStyleSele(styleChecked2)">
                                  {{styleChecked2}}
                                </a>
                                <template  v-for="(item, index) in styleSelect">
                                  <a href="javascript:;" 
                                     :data-option="item"
                                     @click="getStyleSele(item)"
                                     :key="index">
                                    {{item}}
                                  </a>
                                </template>
                            </div>
                        </div>
                    </div>
                    <!-- 产品 -->
                    <div class="item" v-if="proSelect">
                        <div class="list">
                            <div class="tit" @click="itmToggle($event,2)">
                                <i><img src="../../assets/images/iconimgfselew1.png" alt=""></i>
                                <span>{{proChecked}}</span>
                                <div class="san">
                                    <img src="../../assets/images/sanjiaoseleimgf1.png" alt="">
                                </div>
                            </div>
                            <div class="f_jut" >
                                <a href="javascript:;" @click="proChecked=proChecked2;categoryId=''">
                                  {{proChecked2}}
                                </a>
                                <template  v-for="(item, index) in proSelect">
                                  <a href="javascript:;" 
                                     :data-option="item"
                                     @click="getProSele(index)"
                                     :key="index">
                                    {{item.name}}
                                  </a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="weeb_load basefont40" style="padding-bottom: 1.6rem"  v-if="error==0">加载中....</div>
        <div class="f_cont" v-else-if="error==1">
            <!-- <div class="img">
                <img :src="brlitu" alt="">
            </div> -->
            <swiper :options="brandlist1_cont" class="brandlist1_cont" ref="">
              <swiper-slide v-for="(item,index) in brandlist" :key="index">
                <!-- :style="'background-image: url('+item.brandPic+')'" -->
                  <div class="cont" :style="'background-image: url('+item.brandPic+')'" >
                    <router-link :to="{name: 'Brandlistinfo',params: {userid: item.id}}" class="item" >
                        <div class="f_tu">
                          <div class="f_icon">
                              <img :src="item.logo" />
                          </div>
                        </div>
                        <!-- <div class="font">
                          
                          <div class="li">
                              <div class="fzli" v-for="(it,index) in item.seriesList" :key="index">{{it}}</div>
                          </div>
                          <div class="xilie">
                              <div class="list" v-for="(it1,_in) in item.carTypeList" :key="_in">
                                  <i><img src="../../assets/images/dagoub2piconimgf1.png" alt=""></i>
                                  <span>{{it1}}</span>
                              </div>  
                          </div>
                        </div> -->
                    </router-link>
                  </div>
              </swiper-slide>
              <div class="swiper-button-prev swiper-button-white base-prev brandlist1_prev" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-white base-next brandlist1_next" slot="button-next"></div>
            </swiper>
        </div>
        <div class="weeb_load basefont40" style="padding-bottom: 1.6rem"  v-else>没有内容</div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Brandlist",
  components: {
      swiper,
    swiperSlide,
    Header,
    Footer
  },
  data(){
    return {
      error: 0,
        // 系列图片
      brandlist1_cont: {
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        slidesPerColumn: 2,
        speed: 900,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.brandlist1_next',
          prevEl: '.brandlist1_prev',
        },
         breakpoints: {
            991: {  //当屏幕宽度小于等于1280
            slidesPerView: 3,
            slidesPerColumn: 2,
            },
            768: {  //当屏幕宽度小于等于768
            slidesPerView: 2,
            slidesPerColumn: 2,
            },
            567: {  //当屏幕宽度小于等于320
            slidesPerView: 2,
            slidesPerColumn: 2,
            }
         }
      },
      // 系列图片
      hm2_cont: {
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView : 4,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        speed: 900,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.hm2_next',
          prevEl: '.hm2_prev',
        },
        breakpoints: {
          991: {  
            slidesPerView: 3,
          },
          767: {  
            slidesPerView: 2,
          }
        } 
      },
      brandlist: {},
      brandClassify:[],
      xie: "",
      attrId: "",
      brlitu: "",
      fenlei: "产品分类",
      regionSelect: "", //地区下拉内容
      styleSelect: "",  //风格下拉内容
      proSelect: "",  //产品下拉内容
      regionChecked: "选择地区",
      styleChecked: "选择风格",
      proChecked: "产品分类",
      regionChecked2: "选择地区",
      styleChecked2: "选择风格",
      proChecked2: "产品分类",
      categoryId: "", //产品分类id  传过去的值
    }
  },
  beforeCreate(){
    
  },
  created () {
      
  },
  mounted () {
    var _this = this;
    // 获取筛选列表
    function getRegionClass(){  //地区分类接口
      return _this.$axios.post('/api2/portal/article/product/attr/getByIdForPC',{
         "id": "1632238426241732611"
      });
    }
    function getStyleClass(){  //风格分类接口
      return _this.$axios.post('/api2/portal/article/product/attr/getByIdForPC',{
         "id": "1632238426241732610"
      });
    }
    function getProClass(){  //产品分类接口
      return _this.$axios.post('/api2/portal/product/category/list',{
        "current":1,
        "size":10000
      });
    }
    this.$axios.all([getRegionClass(),getStyleClass(),getProClass()]).then( res => {
      //  console.log(res);
        this.regionSelect = res[0].data.attrValueList;
        this.proSelect = res[2].data.data;
        this.styleSelect = res[1].data.attrValueList;
        
       
    }).catch(error => {
      console.log(error);
    });

    /* 页面加载的时候获取品牌列表 */
    this.regionChecked = this.$route.query.title;
    this.getBrandList();
  },
  beforeUpdate(){
    this.xie = this.$route.query.title;
    document.title = this.xie;
    this.regionChecked = this.$route.query.title?this.xie:'选择地区';
  },
  methods: {
    brandSelect(data){
      console.log(data);
      this.regionChecked2 = data.b2p.filter_text1;
      this.styleChecked2 = data.b2p.filter_text2;
      this.proChecked2 = data.b2p.filter_text3;

    },
    qieh:function(bag1){
        this.brlitu = bag1;
    },
    /* 下拉切换 */
    itmToggle(event,_in){
          var e = e || event;
          var that = this;
          var _this = $(".brandlist-sec1 .fom .cont .item").eq(_in).find(".list .tit");
          _this.parent().find(".f_jut").toggle();
          _this.parent().toggleClass("on");
          _this.parents(".item").siblings().find(".f_jut").hide();
          _this.parents(".item").siblings().find(".list").removeClass("on");
          e.stopPropagation();
          _this.parent().find(".f_jut a").click(function(e){
              e.stopPropagation ();
              // that.brandClassify[_in].fval = $(this).html();
              _this.parent().find(".f_jut").hide();
              _this.parent().removeClass("on");
             
          });
          $(document).one("click",function(e){
            e.stopPropagation ();
            _this.parent().find(".f_jut").hide();
            _this.parent().removeClass("on");
          })
    },
    getRegionSele(_val){ //获取地区分类下拉
        this.regionChecked = _val;
        this.$router.push({query: {title:_val=="选择地区"?'':_val}});
        this.$nextTick( () => {
          this.getBrandList();
        });
    },
    getStyleSele(_val){ //获取风格分类下拉
        this.styleChecked = _val;
        this.$nextTick( () => {
          this.getBrandList();
        });
    },
    getProSele(_in){ //获取产品分类下拉
      this.proChecked = this.proSelect[_in].name;
      this.categoryId = this.proSelect[_in].id;
      this.$nextTick( () => {
        this.getBrandList();
      });
    },
    getBrandList(){ //获取品牌列表
      let firstValue = this.regionChecked=="选择地区"?'':this.regionChecked;
      let secondValue = this.styleChecked=="选择风格"?'':this.styleChecked;
      this.$axios.post('/api2/portal/product/brand/findByConditionForPC',{
        "categoryId": this.categoryId,
        firstValue: firstValue,
        secondValue: secondValue,
        current: 1,
        size: 100000,
      }).then(res => {
        if(res.code="000000"){
          this.brandlist = res.data.data;
          if(res.data.data.length!=0){
            this.brlitu = res.data.data[0].brandPic;
            this.error=1;
          }else {
            this.error=2;
          }
        }else{
          this.error=2;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  watch:{
    $route(to,from){
      console.log(this.regionChecked);
      this.regionChecked = this.$route.query.title;
       this.getBrandList();
    }
  },
}
</script>
